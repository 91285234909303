import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";

import Heading from "../components/Heading";
import Card from "../components/Card";
import { Seo } from "../base/Seo";
import Layout from "../components/Layout";

import "./post_category.scss";

export default function post_category() {
  return (
    <>
      <Seo title="Categories" />
      <Layout></Layout>
    </>
  );
}
